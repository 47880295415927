export const msalConfig = {
    auth: {
        clientId: "073d0db2-83d3-4c55-8ad8-d6b202ee7cea",
        authority: "https://login.microsoftonline.com/0f16d077-bd82-4a6c-b498-52741239205f",
        redirectUri: window.location.origin,//"http://localhost:3000/",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};
