import { addMonths, format } from "date-fns";
import { nb } from "date-fns/locale";

export function getPeriodNameByPeriod(period) {
    const dt = new Date(period.year, period.month, 1);
    return format( dt, 'MMMM yyyy', {locale: nb}).replace(/./,c => c.toUpperCase());
}

export function getMonthNameByPeriod(period) {
    const dt = new Date(period.year, period.month, 1);
    return format( dt, 'MMMM', {locale: nb}).replace(/./,c => c.toUpperCase());
}

export function getPeriodAddMonth(period, months){
    const dt = new Date(period.year, period.month-1, 1);
    const ndt = addMonths(dt, months);
    return(getPeriod(ndt.getFullYear(),ndt.getMonth()+1));
}

export function getPeriod(year, month){
    return ({year,month});
}
      