import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import Download from "./Download";
import Projects from "./Projects";
import { fetchAccounts, fetchProjectsWithSubSupliers, fetchProjectsWithTimeEntries } from "./services/HarvestService";
import { getDate, parse, add} from "date-fns";
import { pdf } from "@react-pdf/renderer";
import PdfInvoice from "./PdfInvoice";
import { getMonthNameByPeriod, getPeriodAddMonth, getPeriodNameByPeriod } from "./utiil/Dates";
import { currencyFormate } from "./utiil/Formate";


const Accounts = () => {
  const lastMonth = add(new Date(),{months:-1});
  const [accounts, setAccounts] = useState([]);
  const [waitingForData, setWaitingForData] = useState(true);
  const [downloadStarted, setDownloadStarted] = useState(false)
  const [period, setPeriod] = useState({
    year: lastMonth.getFullYear(),
    month: lastMonth.getMonth(),
  });
  const periodName = getPeriodNameByPeriod(period);

  
  useEffect(() => {
    
    const updateWithProjectsWithTimeEntries = async (allAccounts, period, account, projectWithSubSupliers) => {
      const accountId = account.id;
      const data = await fetchProjectsWithTimeEntries(accountId, period);
      console.log(period);
      for (const entry of data.time_entries) {  
        const hours = entry.rounded_hours ? parseFloat(entry.rounded_hours) : parseFloat(entry.hours);
        const spent_date = getDate(parse(entry.spent_date, "yyyy-MM-dd", new Date()));
        const amount = hours * entry.billable_rate;
        addEntry(
          allAccounts,
          period,
          account,
          entry.client,
          entry.project,
          entry.user,
          entry.task.name,
          spent_date,
          hours,
          amount
        );
        
        if(projectWithSubSupliers[entry.project.code + "-S"]){
          const subSupProject = projectWithSubSupliers[entry.project.code + "-S"]
          addEntry(
            allAccounts,
            period,
            subSupProject.account,
            subSupProject.client,
            subSupProject,
            entry.user,
            entry.task.name,
            spent_date,
            hours,
            amount,
            true
          );
        }
      };
    };

    const setHarvestData = async () => {
      setWaitingForData(true);

      const projectWithSubSupliers = {}
      const harvestAccounts = await fetchAccounts();
      console.log(harvestAccounts);
      for (const account of harvestAccounts) {
        const projects = await fetchProjectsWithSubSupliers(account.id);
        for (const project of projects) {
          projectWithSubSupliers[project.code] = {account:  {id:account.id, name:account.name} , ...project}
        }
      }
      const allAccounts = {};
      for (const account of harvestAccounts) {
        await updateWithProjectsWithTimeEntries(allAccounts, period, account, projectWithSubSupliers);
      }
      setAccounts(allAccounts);
      setWaitingForData(false);
    };

    setHarvestData();
  }, [period]);

  const handleDownloadClick = async () => {

    setDownloadStarted(true);
    const zip = new JSZip();
    for (const account of Object.values(accounts)) {
      for (const project of Object.values(account.projects)) {
        if (project.client.name !== "Varianttid") {
          const illegalCharsRegEx = /[|&;$%@"<>/+,]/g;
          
          const fileName = account.name +
          "/" +
          (project.client.name +
          "-" +
          project.code +
          "-" +
          project.name +
          ".pdf").replace(illegalCharsRegEx, "_");

          zip.file(
            fileName,
            pdf(<PdfInvoice project={project} />).toBlob()
          );
        }
      }
    }

    const blob = await zip.generateAsync({ type: "blob" })
    saveAs(blob,  (period.month+1).toString().padStart(2, '0') + " - " + getMonthNameByPeriod(period) + " - Fakturagrunnlag.zip");
    setDownloadStarted(false);
  };

  const onAddMounth = (step) => {
    setPeriod(getPeriodAddMonth(period, step));
  };

  const accountList = Object.values(accounts).map((account) => {
    return (
      <div className="accounts" key={account.id}>
        <h3>
          {account.name} ({currencyFormate(account.sum_amount)} kr)
        </h3>
        <Projects projects={account.projects} />
      </div>
    );
  });

  return (
    <>
      <header className="App-header">
        <h1>Fakturaunderlag</h1>
        <h2>Alle selskaper med timeføring i {periodName.toLocaleLowerCase()}</h2>
      </header>
      <div className="buttons">
        <div className="period-select">
          <button onClick={() => onAddMounth(-1)}>←</button>
          <div className="period-name">{periodName}</div>
          <button onClick={() => onAddMounth(1)}>→</button>
        </div>
        <Download downloading={downloadStarted} handleOnClick={handleDownloadClick} />
      </div>
      {waitingForData && 
        <div className="waiting-for-data">  
          <h3>Venter på data...</h3>        
        </div>
      }
      {!waitingForData && 

      <div className="account-list">{accountList}</div>
      }
    </>
  );
};
export default Accounts;

const addEntry = (accounts, period, account, client, project, user, task, spent_date, hours, amount, isSubSuplier) => {
  
  if (!accounts[account.id]) {
    accounts[account.id] = account;
    accounts[account.id].projects = {} 
    accounts[account.id].sum_amount = 0;
    accounts[account.id].sum_hours = 0;
  }
  const projects = accounts[account.id].projects;
  
  const projectId = project.code || project.id;
  if (!projects[projectId]){
    projects[projectId] = {
      period: period,
      entries: {},
      client: client,
      ...project,
      sum_amount: 0,
      sum_hours: 0,
      users: {},
      data:[]
    }
  }
  projects[projectId].data.push({spent_date, period, user, task,   hours, amount, isSubSuplier})

  if (!(projects[projectId].entries[spent_date])) {
    projects[projectId].entries[spent_date] = 0;
  }
  
  const userId = user.id;
  const users = projects[projectId].users;
  if (!(users[userId])) {
    users[userId] = {
      ...user,
      sum_hours: 0,
      entries: {},
    };
  }
  
  const entries = users[userId].entries;
  if (!entries[spent_date]) {
    entries[spent_date] = {
      hours: 0,
      amount: 0,
    };
  }

  projects[projectId].entries[spent_date] += hours;
  projects[projectId].sum_hours += hours;
  projects[projectId].sum_amount += amount;
  users[userId].sum_hours += hours;
  users[userId].amount += amount;
  entries[spent_date].amount += amount;
  entries[spent_date].hours += hours;
  if (!isSubSuplier){
    accounts[account.id].sum_hours += hours;
    accounts[account.id].sum_amount += amount;
  } 

  return accounts;
};
