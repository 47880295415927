import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import {  Route, Routes } from 'react-router-dom';
import Accounts from './Accounts';
import './App.css';
import { loginRequest } from './auth/config';

function App() {
  
  const {instance} = useMsal();
  const handleLogInClick = () => {
    instance.loginRedirect(loginRequest);
  }
  

  return (
    <div className="App">
      <UnauthenticatedTemplate>
        <div className='log-on'>
          <button className='log-on-button' onClick={handleLogInClick}>
            Logg på
          </button>
        </div>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Accounts/>}/>
        </Routes>
      </AuthenticatedTemplate>
    </div>
  );
}

export default App;
