import PdfInvoice from "./PdfInvoice";
import { pdf } from '@react-pdf/renderer';
import { saveAs } from "file-saver";
import { formatNoGroup, formatShortDate } from "./utiil/Formate";



const ProjectDetails = ({ show, onClose,  project}) => {

    const handlePDFDownload = async () => {
        const pdfInvoice = await pdf(<PdfInvoice project={project}/>).toBlob();
        const file = new Blob([pdfInvoice], { type: 'application/pdf' });
        saveAs(file, project.code + ".pdf")
    }

    const handleCSVDownload = async () => {
        const headers = ['ProjectCode', 'ProjectName', 'SpentDate', 'User', 'Task', 'Hours', 'Amount'].join(";").concat("\n"); 
        const data = project.data.map ( d => {
            return [project.code,  
                project.name, 
                formatShortDate(new Date(d.period.year, d.period.month, d.spent_date)),  
                d.user.name,  
                d.task,  
                formatNoGroup(d.hours),  
                formatNoGroup(d.amount) 
            ].join(";")
        }).join("\n");

        const  blob = new Blob(["\uFEFF"+headers.concat(data)], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, project.code + ".csv")
    }



    if (!show){
        return (
            <></>
        )
    } 

    return (
        <div className="modal">
            <div className="modal-content">
                <h3>{project.code} - {project.name}</h3>
                <div className="buttons-container">
                    <div className="download-container">
                        <button className="pdf-csv-close-button" onClick={handlePDFDownload}>
                            PDF
                        </button>
                        <button className="pdf-csv-close-button" onClick={handleCSVDownload}>
                            CSV
                        </button>
                    </div>
                    <button className="pdf-csv-close-button" onClick={onClose}>
                        &#10006; LUKK
                    </button>
                </div>

            </div>

        </div>
    )
}
export default ProjectDetails;