import { Text, View } from "@react-pdf/renderer";
import sharedStyles from "./PdfStyles";
import { formate } from "./utiil/Formate";

   

const PdfUser = ({user, lastDay}) => {
    
    const entries = [];
    for (let index = 1; index <= lastDay; index++) {
            if(user.entries[index]){
                entries.push(formate(user.entries[index].hours));
                
            } else {
                entries.push("-");
            }   
    }
    
    const entryList = entries.map((entry, index) => {
        return(<Text key={index} style={sharedStyles.hours_cell}>{entry}</Text>)
    })

    return (
        <View style={sharedStyles.row}>
            <Text style={sharedStyles.name_cell}>{user.name}</Text>
            {entryList}
            <Text style={{...sharedStyles.hours_cell, ...sharedStyles.sum_cell}}>{formate(user.sum_hours)}</Text>
        </View>
           
    )
};

export default PdfUser;
